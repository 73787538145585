import React from 'react'

import { ModalContextProvider } from './src/shared/context/modal-context'
import { UserContextProvider } from './src/shared/context/user-context'
import './src/styles/index.scss'
import './src/shared/styles/index.scss'

export const wrapRootElement = ({ element }) => (
	<UserContextProvider>
		<ModalContextProvider>{element}</ModalContextProvider>
	</UserContextProvider>
)
